var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { invalid: _vm.state == false } },
    [
      _c(
        "multiselect",
        {
          staticClass: "no-border-multiselect",
          attrs: {
            openDirection: "bottom",
            placeholder: "Search by practice name",
            "custom-label": _vm.practiceLabel,
            "track-by": "practice_id",
            options: _vm.selectablePractices,
            loading: _vm.loading || _vm.practicesLoading,
            "close-on-select": _vm.closeOnSelect,
            multiple: _vm.multiple,
            "show-labels": false,
            showNoOptions: true,
          },
          on: {
            "search-change": _vm.debouncePractices,
            open: _vm.debouncePractices,
          },
          model: {
            value: _vm.selectedPractice,
            callback: function ($$v) {
              _vm.selectedPractice = $$v
            },
            expression: "selectedPractice",
          },
        },
        [
          _c("template", { slot: "noResult" }, [
            _vm._v(" No practices matched your search "),
          ]),
          _c("template", { slot: "noOptions" }, [
            _vm._v(" Start typing a practice name... "),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }